<template>
  <div class="content mt-12">
    <div
      class="brq pl-2 my-5"
      :style="`border-left: ${theme.midColor} 6px solid;`"
    >
      <div class="sub-title">解析</div>
    </div>
    <div class="page-passage" v-html="text"></div>
    <v-divider class="mt-8 mb-13"></v-divider>
  </div>
</template>

<script>
export default {
  name: "pageExplain",
  props: {
    theme: {
      type: Object,
      default: () => ({}),
    },
    text: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 0 1.5rem;

  .page-passage {
    vertical-align: bottom;
    font-family: Arvo, sans-serif;
    text-align: justify;
    letter-spacing: 0.3rem;
    line-height: 2;
    //word-spacing: -0.2rem;
    word-break: break-all;
    font-size: 0.9rem;
  }
}
</style>
